<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div :class="{ 'blur-content': this.isLoading }">
      <div class="main-wrapper">
        <!--begin::sidebar-->
        <sidebar-component classMenu="TransportOrderCt"></sidebar-component>
        <!--end::sidebar-->

        <div class="page-wrapper">
          <!--begin::navbar-->
          <navbar-component></navbar-component>
          <!--end::navbar-->

          <!--begin::content-->
          <div class="page-content">


            <br>
            <ul class="nav nav-tabs nav-tabs-line" id="lineTab" role="tablist">
              <li class="nav-item" @click="hideTabsMaps()" v-if="detailUser.company_detail.code == 'BP' || detailUser.company_detail.code == 'LINC'" >
                <a 
                
                :class="(detailUser.company_detail.code == 'BP' || detailUser.company_detail.code == 'LINC')?'nav-link active':'nav-link'"
                id="pnl-line-tab" data-bs-toggle="tab" href="#submitted" role="tab"
                  aria-controls="void" aria-selected="false">Submitted <span class="badge bg-danger">
                    {{ totalSubmitted }}
                  </span></a>
              </li>

              <li class="nav-item" @click="hideTabsMaps()">
                <a 
                :class="(detailUser.company_detail.code !== 'BP' && detailUser.company_detail.code !== 'LINC')?'nav-link active':'nav-link'"
                id="transport-line-tab" data-bs-toggle="tab" href="#transport" role="tab"
                  aria-controls="transport" aria-selected="true">New <span class="badge bg-danger">
                    {{ totalNew }}
                  </span></a>
              </li>
              <li class="nav-item" @click="hideTabsMaps()">
                <a class="nav-link" id="assigned-line-tab" data-bs-toggle="tab" href="#assigned" role="tab"
                  aria-controls="sssigned" aria-selected="false">Assigned <span class="badge bg-danger">
                    {{ totalAssigned }}
                  </span></a>
              </li>
              <li class="nav-item" @click="hideTabsMaps()" v-if="detailUser.company_detail.code == 'BP' || detailUser.company_detail.code == 'LINC'">
                <a class="nav-link" id="rejected-line-tab" data-bs-toggle="tab" href="#rejected" role="tab"
                  aria-controls="rejected" aria-selected="false">Rejected <span class="badge bg-danger">
                    {{ totalRejected }}
                  </span></a>
              </li>
              <li class="nav-item" @click="hideTabsMaps()">
                <a class="nav-link" id="completed-line-tab" data-bs-toggle="tab" href="#completed" role="tab"
                  aria-controls="completed" aria-selected="false">Completed <span class="badge bg-danger">
                    {{ totalCompleted }}
                  </span></a>
              </li>
              <li class="nav-item" @click="hideTabsMaps()" v-if="detailUser.company_detail.code == 'BP' || detailUser.company_detail.code == 'LINC'">
                <a class="nav-link" id="void-line-tab" data-bs-toggle="tab" href="#void" role="tab" aria-controls="void"
                  aria-selected="false">Void <span class="badge bg-danger">
                    {{ totalVoid }}
                  </span></a>
              </li>

              <li class="nav-item" @click="hideTabsMaps()" v-if="permision_role_.method_custom2">
                <a class="nav-link" id="pnl-line-tab" data-bs-toggle="tab" href="#pnl" role="tab" aria-controls="void"
                  aria-selected="false">PNL <span class="badge bg-danger">
                    {{ totalPnl }}
                  </span></a>
              </li>


              <!-- <li class="nav-item" @click="hideTabsMaps()">
                <a class="nav-link" id="reschedule-line-tab" data-bs-toggle="tab" href="#reschedule" role="tab"
                  aria-controls="reschedule" aria-selected="false">Reschedule <span class="badge bg-danger">
                    {{ totalReschedule }}
                  </span></a>
              </li>

              <li class="nav-item" @click="hideTabsMaps()">
                <a class="nav-link" id="reroute-line-tab" data-bs-toggle="tab" href="#reroute" role="tab"
                  aria-controls="reroute" aria-selected="false">Reroute <span class="badge bg-danger">
                    {{ totalReroute }}
                  </span></a>
              </li> -->

              <li class="nav-item ">
                <h5 style="margin-top:10px;margin-right: 5px">Choose Date : </h5>
              </li>
              <li class="nav-item ">
                <date-range-picker @update="filterDateRange" style="width: 100%" control-container-class="form-control"
                  :locale-data="locale" v-model="dateFilter" :opens="opens" :singleDatePicker="false"
                  :timePicker24Hour="true" :timePicker="true" :showWeekNumbers="false">
                </date-range-picker>

              </li>
              <li class="nav-item">
                <button class="btn btn-warning float-end btn-sm" style="margin-left: 5px"
                  @click.prevent="filterDateRange">
                  Go
                </button>
              </li>
            </ul>

            <div class="row" v-if="showMaps">
              <div class="col-lg-10">
                <gmap-map :center="center" :zoom="zoom" style="width: 100%; height: 400px">
                  <gmap-marker :key="index" v-for="(gmp, index) in locations" :position="gmp" :icon="gmp.icon"
                    @click="openMarker(gmp)" :clickable="true">
                    <!-- <gmap-info-window :opened="true">
                          <span
                            class="badge"
                            :style="{
                              'background-color': gmp.status_color,
                              color: '#67001a',
                            }"
                            :opened="false"
                          >
                            {{ gmp.label }}
                          </span>
                       
                        </gmap-info-window> -->
                  </gmap-marker>

                  <!-- <DirectionsRenderer
                        travelMode="DRIVING"
                        :origin="startLocation"
                        :destination="endLocation"
                      /> -->

                  <!-- <gmap-info-window :position="{ lat:-6.1850691, lng: 106.795189 }">
                        Hello world!
                      </gmap-info-window> -->
                </gmap-map>
              </div>
              <div class="col-lg-2">
                <div class="card">
                  <div class="card-header">All Priority</div>
                  <div class="card-body">
                    <div class="row">
                      <span class="badge " @click="openPriority(1, '#ff7502', 'white')"
                        style="background-color:#ff7502; cursor:pointer; --bs-bg-opacity: 1;">Priority 1</span>
                    </div>
                    <div style="margin-top:10px"></div>
                    <div class="row">
                      <span class="badge " @click="openPriority(2, '#0d6439', 'white')"
                        style="background-color:#0d6439; cursor:pointer;  --bs-bg-opacity: 1;">Priority 2</span><br>
                    </div>
                    <div style="margin-top:10px"></div>
                    <div class="row">
                      <span class="badge" @click="openPriority(3, '#b25cff', 'white')"
                        style="background-color:#b25cff;cursor:pointer; --bs-bg-opacity: 1;">Priority 3</span>
                    </div>
                    <div style="margin-top:10px"></div>
                    <div class="row">
                      <span class="badge " @click="openPriority(4, '#cc080c', 'white')"
                        style="background-color:#cc080c;cursor:pointer; --bs-bg-opacity: 1;">Priority 4</span>
                    </div>
                    <div style="margin-top:10px"></div>
                    <div class="row">
                      <span class="badge text-dark" @click="openPriority(5, '#ffff00', 'black')"
                        style="background-color:#ffff00;cursor:pointer; --bs-bg-opacity: 1;">Priority 5</span>
                    </div>
                    <div style="margin-top:10px"></div>
                    <div class="row">
                      <span class="badge " @click="openPriority(6, '#0000ff', 'white')"
                        style="background-color:#0000ff;cursor:pointer; --bs-bg-opacity: 1;">Priority 6</span>
                    </div>
                    <div style="margin-top:10px"></div>
                    <div class="row">
                      <span class="badge " @click="openPriority(7, '#631547', 'white')"
                        style="background-color:#631547;cursor:pointer; --bs-bg-opacity: 1;">Priority 7</span>
                    </div>
                    <div style="margin-top:10px"></div>
                    <div class="row">
                      <span class="badge text-dark" @click="openPriority(8, '#12ff97', 'black')"
                        style="background-color:#12ff97;cursor:pointer; --bs-bg-opacity: 1;">Priority 8</span>
                    </div>
                    <div style="margin-top:10px"></div>
                    <div class="row">
                      <span class="badge text-white" @click="openPriority(9, '#ff00ff', 'white')"
                        style="background-color:#ff00ff;cursor:pointer; --bs-bg-opacity: 1;">Priority 9</span>
                    </div>
                    <div style="margin-top:10px"></div>
                    <div class="row">
                      <span class="badge text-dark" @click="openPriority(10, '#43abff', 'black')"
                        style="background-color:#43abff;cursor:pointer; --bs-bg-opacity: 1;">Priority 10</span>
                    </div>




                  </div>
                </div>
              </div>

            </div>

            <div class="tab-content mt-3" id="lineTabContent">
              <div 
              :class="(detailUser.company_detail.code == 'BP' || detailUser.company_detail.code == 'LINC')?'tab-pane fade show active':'tab-pane fade'" 
              id="submitted" role="tabpanel" aria-labelledby="pnl-line-tab">
                <transport-order-submitted :date-range="dateFilter" :status="'SUBMITTED-CT'"
                  @passDataSubmitted="getDataSubmitted($event)"
                  @show_order="getOrderNo($event)"></transport-order-submitted>
              </div>

              <div 
              :class="(detailUser.company_detail.code !== 'BP' && detailUser.company_detail.code !== 'LINC')?'tab-pane fade show active':'tab-pane fade'" 
              id="transport" role="tabpanel" aria-labelledby="transport-line-tab">
                <transport-view :date-range="dateFilter" :status="'NEW-CT'" @passDataNew="getDataNew($event)"
                  @show_order="getOrderNo($event)"></transport-view>
              </div>
              <div class="tab-pane fade" id="assigned" role="tabpanel" aria-labelledby="assigned-line-tab">
                <transport-assigned-view :date-range="dateFilter" :status="'ASSIGNED-CT'"
                  @passDataAssigned="getDataAssigned($event)"
                  @show_order="getOrderNo($event)"></transport-assigned-view>
              </div>
              <div class="tab-pane fade" id="rejected" role="tabpanel" aria-labelledby="rejected-line-tab">
                <transport-rejected-view :date-range="dateFilter" :status="'REJECTED'"
                  @passDataRejected="getDataRejected($event)"
                  @show_order="getOrderNo($event)"></transport-rejected-view>
              </div>
              <div class="tab-pane fade" id="completed" role="tabpanel" aria-labelledby="completed-line-tab">
                <transport-completed-view :date-range="dateFilter" :status="'COMPLETED'"
                  @passDataCompleted="getDataCompleted($event)"
                  @show_order="getOrderNo($event)"></transport-completed-view>
              </div>
              <div class="tab-pane fade" id="void" role="tabpanel" aria-labelledby="void-line-tab">
                <transport-void-view :date-range="dateFilter" :status="'VOID-CT'" @passDataVoid="getDataVoid($event)"
                  @show_order="getOrderNo($event)"></transport-void-view>
              </div>

              <div class="tab-pane fade" id="pnl" role="tabpanel" aria-labelledby="pnl-line-tab">
                <transport-order-pnl :date-range="dateFilter" :status="'PNL_NEED_APPROVAL'"
                  @passData="getDataPnl($event)" @show_order="getOrderNo($event)"></transport-order-pnl>
              </div>




              <!-- <div class="tab-pane fade" id="reschedule" role="tabpanel" aria-labelledby="reschedule-line-tab">
                <transport-reschedule-view :date-range="dateFilter" :status="'RESCHEDULE-CT'"
                  @passDataReschedule="getDataReschedule($event)" @show_order="getOrderNo($event)"></transport-reschedule-view>
              </div>
              <div class="tab-pane fade" id="reroute" role="tabpanel" aria-labelledby="reroute-line-tab">
                <transport-reroute-view :date-range="dateFilter" :status="'REROUTE-CT'"
                  @passDataReroute="getDataReroute($event)" @show_order="getOrderNo($event)"></transport-reroute-view>
              </div> -->
            </div>






          </div>
          <!--end::content-->


          <!-- @rejectData --->
          <!-- <modal-reject-data  
      :showModal="showModalRejectData"
      :data="this.transport_order_header_ids"
      @closeModal="closeMyModal"
    ></modal-reject-data>
 -->


          <!--begin::footer-->
          <footer-component></footer-component>
          <!--end::footer-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import DateRangePicker from "vue2-daterange-picker";
import moment from 'moment';
import transportView from "@/components/orders/control-tower/TransportNew.vue";
import transportAssignedView from "@/components/orders/control-tower/TransportAssigned.vue";
import transportRejectedView from "@/components/orders/control-tower/TransportRejected.vue";
import TransportCompletedView from "@/components/orders/control-tower/TransportCompleted.vue";
import TransportVoidView from "@/components/orders/control-tower/TransportVoid.vue";
import TransportOrderCtPnl from "@/components/orders/control-tower/TransportOrderCtPnl.vue";
import TransportOrderSubmitted from "@/components/orders/control-tower/TransportSubmitted.vue";
// import TransportOrderReschedule from "@/components/orders/control-tower/TransportOrderReschedule.vue";
// import TransportOrderReroute from "@/components/orders/control-tower/TransportOrderReroute.vue";

export default {
  name: "TransportOrderCt",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "transport-view": transportView,
    "transport-assigned-view": transportAssignedView,
    "transport-rejected-view": transportRejectedView,
    "transport-completed-view": TransportCompletedView,
    "transport-void-view": TransportVoidView,
    "transport-order-pnl": TransportOrderCtPnl,
    "transport-order-submitted": TransportOrderSubmitted,
    // "transport-reschedule-view": TransportOrderReschedule,
    // "transport-reroute-view": TransportOrderReroute,
    DateRangePicker
    // "modal-reject-data": ModalTransportOrderCtRejectMass,
  },
  data() {
    const startDate = moment().subtract(7, 'd').format('YYYY-MM-DD hh:mm:ss');
    //const startDate = new Date();
    const endDate = moment(Date.now() + 7 * 24 * 3600 * 1000).format('YYYY-MM-DD');
    return {
      center: {
        lat: -2.548926,
        lng: 118.0148634,
      },
      zoom: 5,
      showMaps: false,
      filterDateRangeValue: false,
      opens: "center",
      totalNew: 0,
      totalAssigned: 0,
      totalRejected: 0,
      totalCompleted: 0,
      totalReschedule: 0,
      totalReroute: 0,
      totalVoid: 0,
      totalPnl: 0,
      totalSubmitted: 0,
      locale: {
        format: "yyyy-mm-dd",
      },
      dateFilter: {
        startDate,
        endDate,
      },

      showModalRejectData: false,
      maxToasts: 100,
      ORDER_RESPONSE_TIME: 0,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      permision_role_: [],
      locations: [],
      transport_order_header_ids: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      // columns: [
      //   {
      //     label: this.$t("submit_time_txt"),
      //     field: "submit_time",
      //     type: 'date',
      //     dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
      //     dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
      //     filterOptions: {
      //       enabled: true, // enable filter for this column
      //       placeholder: "Filter By "+this.$t("submit_time_txt"), // placeholder for filter input
      //       filterValue: "", // initial populated value for this filter
      //       filterDropdownItems: [], // dropdown (with selected values) instead of text input
      //       trigger: "enter", //only trigger on enter not on keyup
      //     },
      //   },
      //   {
      //     label: this.$t("postpone_time_txt"),
      //     field: "postpone_time",
      //     type: 'date',
      //     dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
      //     dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
      //     filterOptions: {
      //       enabled: true, // enable filter for this column
      //       placeholder: "Filter By "+this.$t("postpone_time_txt"), // placeholder for filter input
      //       filterValue: "", // initial populated value for this filter
      //       filterDropdownItems: [], // dropdown (with selected values) instead of text input
      //       trigger: "enter", //only trigger on enter not on keyup
      //     },
      //   },
      //   {
      //     label: this.$t("companyTxt"),
      //     field: "company.name",
      //     filterOptions: {
      //       enabled: true, // enable filter for this column
      //       placeholder: "Filter By "+this.$t("companyTxt"), // placeholder for filter input
      //       filterValue: "", // initial populated value for this filter
      //       filterDropdownItems: [], // dropdown (with selected values) instead of text input
      //       trigger: "enter", //only trigger on enter not on keyup
      //     },
      //   },
      //   {
      //     label: this.$t("order_no_txt"),
      //     field: "order_no",
      //     filterOptions: {
      //       enabled: true, // enable filter for this column
      //       placeholder: "Filter By "+this.$t("order_no_txt"), // placeholder for filter input
      //       filterValue: "", // initial populated value for this filter
      //       filterDropdownItems: [], // dropdown (with selected values) instead of text input
      //       trigger: "enter", //only trigger on enter not on keyup
      //     },
      //   },
      //   {
      //     label: this.$t("order_date_txt"),
      //     field: "order_date",
      //     type: 'date',
      //     dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
      //     dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
      //     // dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
      //     // dateOutputFormat: 'dd MMM yyyy',
      //     filterOptions: {
      //       customFilter: true,
      //       enabled: true, // enable filter for this column
      //       placeholder: "Filter By Order Date", // placeholder for filter input
      //       filterValue: "", // initial populated value for this filter
      //       filterDropdownItems: [], // dropdown (with selected values) instead of text input
      //       trigger: "enter", //only trigger on enter not on keyup
      //     },
      //   },

      //   {
      //     label: this.$t("order_status_txt"),
      //     field: "order_status",
      //     filterOptions: {
      //       enabled: true, // enable filter for this column
      //       placeholder: "Filter By "+this.$t("order_status_txt"), // placeholder for filter input
      //       filterValue: "", // initial populated value for this filter
      //       filterDropdownItems: ['SUBMITTED','POSTPONE'], // dropdown (with selected values) instead of text input
      //       trigger: "enter", //only trigger on enter not on keyup
      //     },
      //   },

      //   {
      //     label: this.$t("pick_name_txt"),
      //     field: "pick_name",
      //     filterOptions: {
      //       enabled: true, // enable filter for this column
      //       placeholder: "Filter By "+this.$t("pick_name_txt"), // placeholder for filter input
      //       filterValue: "", // initial populated value for this filter
      //       filterDropdownItems: [], // dropdown (with selected values) instead of text input
      //       trigger: "enter", //only trigger on enter not on keyup
      //     },
      //   },

      //   {
      //     label: this.$t("dest_name_txt"),
      //     field: "dest_name",
      //     filterOptions: {
      //       enabled: true, // enable filter for this column
      //       placeholder: "Filter By "+this.$t("dest_name_txt"), // placeholder for filter input
      //       filterValue: "", // initial populated value for this filter
      //       filterDropdownItems: [], // dropdown (with selected values) instead of text input
      //       trigger: "enter", //only trigger on enter not on keyup
      //     },
      //   },

      //   {
      //     label: this.$t("transport_order_template_description_txt"),
      //     field: "transport_order_template.description",
      //     filterOptions: {
      //       enabled: true, // enable filter for this column
      //       placeholder: "Filter By "+this.$t("transport_order_template_description_txt"), // placeholder for filter input
      //       filterValue: "", // initial populated value for this filter
      //       filterDropdownItems: [], // dropdown (with selected values) instead of text input
      //       trigger: "enter", //only trigger on enter not on keyup
      //     },
      //   },

      //   {
      //     label: this.$t("tonnage_txt"),
      //     field: "transport_order_template.tonnage",
      //     filterOptions: {
      //       enabled: true, // enable filter for this column
      //       placeholder: "> 0", // placeholder for filter input
      //       filterValue: "", // initial populated value for this filter
      //       filterDropdownItems: [], // dropdown (with selected values) instead of text input
      //       trigger: "enter", //only trigger on enter not on keyup
      //     },
      //   },

      //   {
      //     label: this.$t("cubic_txt"),
      //     field: "transport_order_template.cubic",
      //     filterOptions: {
      //       enabled: true, // enable filter for this column
      //       placeholder: "> 0", // placeholder for filter input
      //       filterValue: "", // initial populated value for this filter
      //       filterDropdownItems: [], // dropdown (with selected values) instead of text input
      //       trigger: "enter", //only trigger on enter not on keyup
      //     },
      //   },

      //   {
      //     label: this.$t("pickup_plan_txt"),
      //     field: "pick_plan",
      //     filterOptions: {
      //       enabled: true, // enable filter for this column
      //       placeholder: "Filter By "+this.$t("pickup_plan_txt"), // placeholder for filter input
      //       filterValue: "", // initial populated value for this filter
      //       filterDropdownItems: [], // dropdown (with selected values) instead of text input
      //       trigger: "enter", //only trigger on enter not on keyup
      //     },
      //   },

      //   {
      //     label: this.$t("dest_plan_txt"),
      //     field: "dest_plan",
      //     type: 'date',
      //     dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
      //     dateOutputFormat: 'dd MMM yyyy h:mm:ss a',//'dd MMM yyyy',
      //     filterOptions: {
      //       enabled: true, // enable filter for this column
      //       placeholder: "Filter By "+this.$t("dest_plan_txt"), // placeholder for filter input
      //       filterValue: "", // initial populated value for this filter
      //       filterDropdownItems: [], // dropdown (with selected values) instead of text input
      //       trigger: "enter", //only trigger on enter not on keyup
      //     },
      //   },


      //   {
      //     label: this.$t("action_txt"),
      //     field: "actions",
      //     sortable: false,
      //   },
      // ],
      rows: [],
      rowsSelection: [],
      htmlTable: "",
    };
  },
  watch: {},
  computed: {
  },
  methods: {
    getDataNew(res) {
      this.totalNew = res;
    },

    getDataAssigned(res) {
      this.totalAssigned = res;
    },

    getDataRejected(res) {
      this.totalRejected = res;
    },

    getDataCompleted(res) {
      this.totalCompleted = res;
    },
    getDataVoid(res) {
      this.totalVoid = res;
    },

    getDataPnl(res) {
      this.totalPnl = res;
    },

    getDataSubmitted(res) {
      this.totalSubmitted = res;
    },

    getDataReschedule(res) {
      this.totalReschedule = res;
    },

    getDataReroute(res) {
      this.totalReroute = res;
    },

    hideTabsMaps() {
      this.showMaps = false;
    },


    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    getOrderNo(order) {
      this.showMaps = false;
      this.loadItemsMaps(order)
      this.showMaps = true;
    },

    openMarker(detail) {
      let sts_engine = "";
      if (detail.engine_car == "ON") {
        sts_engine = '<a style="color:green;">ON<a>';
      } else {
        sts_engine = '<a style="color:red;">OFF<a>';
      }
      var dataFilterPriority = [];
      for (let index = 0; index < this.locations.length; index++) {
        if (this.locations[index].dataAll.priority == detail.dataAll.priority) {
          dataFilterPriority.push({ vehicle_no: this.locations[index].dataAll.vehicle_no });
        }
      }


      var no = 1;
      this.htmlTable = "";
      for (let i = 0; i < dataFilterPriority.length; i++) {
        this.htmlTable += "<tr>" +
          "<td>" + no++ + "</td>" +
          "<td>" + dataFilterPriority[i].vehicle_no + "</td>" +
          "</tr>";
      }
      this.$swal({
        position: "top-center",
        title: false,
        icon: false,
        showCloseButton: true,
        showConfirmButton: false,
        header: '<a href=""></a>',
        html:
          '<ul class="nav nav-tabs" id="myTab" role="tablist">' +
          '<li class="nav-item">' +
          '<a class="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Basic</a>' +
          "</li>" +

          '<li class="nav-item">' +
          '<span style="margin: 20px; color:">Eng:' +
          sts_engine +
          '<span > AC: <a style="color:red;">OFF</a> </span>' +
          "</li>" +
          "</ul>" +
          '<div class="tab-content border border-top-0 p-3" id="myTabContent">' +
          '<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">' +
          "<table>" +
          "<tr>" +
          "<td style='text-align:left' width='120px'>Vehicle No</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.dataAll.vehicle_no +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Load ID</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.dataAll.load_id +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Equipment</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.dataAll.equipment +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Load Group</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.dataAll.load_group +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Driver Name</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.dataAll.driver_name +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Driver Hp</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.dataAll.driver_hp +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Date Time</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.dataAll.time_gps +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Address</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.dataAll.location +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Speed</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.dataAll.speed +
          "</b></td>" +
          "</tr>" +

          "</table>" +
          "<br>" +
          "<h5 style='text-align:left'>Nopol Lain dalam prioritas yang sama </h5>" +
          "<div style='position: relative;height: 200px;overflow: auto;display: block;' >" +
          "<table class='table table-bordered table-striped'>" +
          "<tr>" +
          "<th style='text-align:center'>No</th>" +
          "<th style='text-align:center'>Vehicle No</th>" +
          "</tr>" +
          this.htmlTable +
          "</div>" +
          "</table>" +
          "</div>"

      });
    },

    openPriority(priority, background, color) {
      var pesan = "";
      if (priority == 1) {
        pesan = "LOKASI DALAM RADIUS 25KM <br> STATUS	: IDLE <br> BELUM DI ASSIGN <br>LISENSI TRUCK TIDAK EXPIRED <br>LISENSI DRIVER TIDAK EXPIRED <br>KAPASITAS 70 % - 100 % DENGAN ORDER";
      }

      if (priority == 2) {
        pesan = "LOKASI DALAM RADIUS 25KM<br>STATUS	: FPPK / ON DUTY<br>WAKTU BONGKAR H+1 MENDEKATI WAKTU PICKUP<br>LISENSI TRUCK TIDAK EXPIRED<br>LISENSI DRIVER TIDAK EXPIRED<br>KAPASITAS 70 % - 100 % DENGAN ORDER";
      }

      if (priority == 3) {
        pesan = "LOKASI DALAM RADIUS 25KM<br>STATUS	: IDLE DI POOL<br>LISENSI TRUCK TIDAK EXPIRED<br>LISENSI DRIVER TIDAK EXPIRED<br>KAPASITAS 70 % - 100 % DENGAN ORDER";
      }

      if (priority == 4) {
        pesan = "LOKASI DALAM RADIUS 25KM<br>STATUS	: SERVICE , WAKTU ESTIMASI SELESAI SERVICE MENDEKATI WAKTU PICKUP<br>LISENSI TRUCK TIDAK EXPIRED<br>LISENSI DRIVER TIDAK EXPIRED<br>KAPASITAS 70 % - 100 % DENGAN ORDER";
      }


      if (priority == 5) {
        pesan = "LOKASI DALAM RADIUS 25KM<br>STATUS	: NO DRIVER<br>LISENSI TRUCK TIDAK EXPIRED<br>KAPASITAS 70 % - 100 % DENGAN ORDER";
      }


      if (priority == 6) {
        pesan = "LOKASI DILUAR RADIUS 25KM<br>STATUS	: IDLE<br>BELUM DI ASSIGN<br>LISENSI TRUCK TIDAK EXPIRED<br>LISENSI DRIVER TIDAK EXPIRED<br>KAPASITAS 70 % - 100 % DENGAN ORDER";
      }


      if (priority == 7) {
        pesan = "LOKASI DILUAR RADIUS 25KM<br>STATUS	: FPPK / ON DUTY<br>WAKTU BONGKAR H+1 MENDEKATI WAKTU PICKUP<br>LISENSI TRUCK TIDAK EXPIRED<br>LISENSI DRIVER TIDAK EXPIRED<br>KAPASITAS 70 % - 100 % DENGAN ORDER";
      }

      if (priority == 8) {
        pesan = "LOKASI DILUAR RADIUS 25KM<br>STATUS	: IDLE DI POOL<br>LISENSI TRUCK TIDAK EXPIRED<br>LISENSI DRIVER TIDAK EXPIRED<br>KAPASITAS 70 % - 100 % DENGAN ORDER";
      }


      if (priority == 9) {
        pesan = "LOKASI DILUAR RADIUS 25KM<br>STATUS	: SERVICE , WAKTU ESTIMASI SELESAI SERVICE MENDEKATI WAKTU PICKUP<br>LISENSI TRUCK TIDAK EXPIRED<br>LISENSI DRIVER TIDAK EXPIRED<br>KAPASITAS 70 % - 100 % DENGAN ORDER";
      }


      if (priority == 10) {
        pesan = "LOKASI DILUAR RADIUS 25KM<br>STATUS	: NO DRIVER<br>LISENSI TRUCK TIDAK EXPIRED<br>KAPASITAS 70 % - 100 % DENGAN ORDER";
      }

      this.$swal({
        background: background,
        position: "top-end",
        title: false,
        icon: false,
        showCloseButton: true,
        showConfirmButton: false,
        header: '',
        html: '<div style="color:' + color + '"><h2>Priority ' + priority + '</h3><hr><span>' + pesan + '</span></div>',


      });
    },
    // closeMyModal() {
    //   this.showModalRejectData = false;
    //   this.loadItems();
    // },
    // checkSubmitDate(row){
    //   const dateNow             = this.$moment(new Date()).format("YYYY-MM-DD h:mm:ss");
    //   const dateData            = this.$moment(row.submit_time).format("YYYY-MM-DD h:mm:ss");
    //   const dateDataPickup      = this.$moment(row.pick_plan);
    //   const diff_in_millis      = Math.abs(new Date(dateData) - new Date(dateNow))
    //   const diff_in_minutes     = diff_in_millis/60000
    //   const precentaceData      = (diff_in_minutes * 100) / 25


    //   var hoursDataPickup = dateDataPickup.diff(dateNow, 'hours')
    //   if(hoursDataPickup > 48){
    //     return "";
    //   }else{

    //     if(diff_in_minutes > this.ORDER_RESPONSE_TIME){

    //       return {
    //         "background-color": "red",
    //         "color":"white"
    //       }

    //     }else if(precentaceData > 25){
    //       return {
    //         "background-color": "yellow",
    //         "color":"black"
    //       }
    //     }else{
    //       return "";
    //     }

    //   }
    // },

    // checkPickPlanDate (val) {
    //   const dateNow   = this.$moment(new Date()).format("YYYY-MM-DD")+" 23:59:59";
    //   const dateData  = this.$moment(val).format("YYYY-MM-DD")+" 23:59:59";
    //   if(new Date(dateData) < new Date(dateNow)){

    //      return {
    //       "background-color": "red",
    //       "color":"white"
    //     }

    //   }else if(new Date(dateData) > new Date(dateNow)){

    //     return ""

    //   }else{

    //      return {
    //       "background-color": "yellow",
    //       "color":"black"
    //     }

    //   }
    // },

    // selectionChanged(ev){
    //   this.rowsSelection=[];
    //   this.transport_order_header_ids=[];
    //   this.rowsSelection=ev.selectedRows;

    //   this.rowsSelection.forEach((item) => {
    //     this.transport_order_header_ids.push(item.transport_order_header_id);
    //     // if(item.order_status=='NEW'){
    //     //   this.error('Order No:'+item.order_status+' Status Not Submited, because status order not NEW or SUBMITED');
    //     // }else{

    //     // }

    //   });

    // },
    filterDateRange() {
      this.showMaps = false;
      var start = moment(String(this.dateFilter.startDate,)).format('YYYY-MM-DD hh:mm:ss');
      var end = moment(String(this.dateFilter.endDate,)).format('YYYY-MM-DD hh:mm:ss');
      this.dateFilter = {
        startDate: start,
        endDate: end
      }
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      let rolePermision_ = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'TransportOrderHeaderCtController') {
          rolePermision = permision;
        }

        if (permision.permission.controller == 'TransportOrderMarginController') {
          rolePermision_ = permision;
        }



      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
      this.permision_role_ = rolePermision_;

      if (this.$route.query.msg) {
        if (this.$route.query.status == 200) {
          this.success(this.$route.query.msg)
        } else {
          this.error(this.$route.query.msg)
        }

      }

    },


    // sendOrder(){

    //   this.$swal({
    //     title: this.$t("areYouSure"),
    //     text: this.$t("yourDataSend"),
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes!",
    //   }).then((result) => {
    //     if (result.value) {
    //       // this.loadingSubmiting();
    //       this.fade(true);
    //       let data={
    //         transport_order_header_ids:this.transport_order_header_ids
    //       }
    //       const baseURI =
    //         this.$settings.endPoint + "control-tower/transport-order-header/send-order-to-blujay";
    //       this.$http
    //         .post(baseURI,data,{ timeout: 30000 })
    //         .then((response) => {
    //           // this.loading();
    //           if (response.data.status === 200) {

    //             // response.data.datas.messages.forEach((item) => {
    //             //   this.success(item);
    //             // });
    //             this.success2(response.data.datas.messages);
    //             this.loadItems();
    //             this.isloading = false;
    //           } else {
    //             this.errors = response.data.errors;
    //             this.resultError(response.data.errors);
    //           }
    //         }).catch(error => {
    //         this.isLoading = false;
    //         if(error.code == "ECONNABORTED"){
    //             this.error(this.$t("check_internet_connection_txt"));
    //         }else{

    //             if (error.response) {
    //             if(error.response.status === 422) {
    //                     this.errors = error.response.data.errors;
    //                     this.resultError(error.response.data.errors);
    //             }else if (error.response.status === 500) {
    //                 this.$router.push('/server-error');
    //             }else if (error.response.status === 401) {
    //                 this.$router.push("/authorized-error");
    //             }else{
    //                 this.$router.push('/page-not-found');
    //             }
    //             }

    //         }

    //         });
    //     }
    //   });




    // },


    // cancelOrder(){
    //   this.showModalRejectData = true;
    // },

    // updateFormatDate(date){
    //   let date_format = this.$moment(date).format("dd mmm yyyy");
    //   return date_format;
    // },

    // downloadData() {
    //   this.isLoading = true;
    //   var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
    //   var file_name = "download_" + CurrentDate + ".xlsx";

    //        const baseURI = this.$settings.endPoint + "control-tower/transport-order-header";
    //   //var columnFilters = this.serverParams.columnFilters;
    //   var date_order = '';
    //   if(this.serverParams.columnFilters.order_date!=''){
    //     date_order =this.$moment(this.serverParams.columnFilters.order_date).format("YYYY-MM-DD");
    //   }
    //   //var columnFilters = this.serverParams.columnFilters;
    //   var pick_plan = '';
    //   if(this.serverParams.columnFilters.pick_plan!=''){
    //     pick_plan =this.$moment(this.serverParams.columnFilters.pick_plan).format("YYYY-MM-DD");
    //   }
    //   //var columnFilters = this.serverParams.columnFilters;
    //   var dest_plan = '';
    //   if(this.serverParams.columnFilters.dest_plan!=''){
    //     dest_plan =this.$moment(this.serverParams.columnFilters.dest_plan).format("YYYY-MM-DD");
    //   }
    //    var company_name = this.serverParams.columnFilters["company.name"];
    //    var transport_order_template_description = this.serverParams.columnFilters["transport_order_template.description"];
    //    var transport_order_template_tonnage     = this.serverParams.columnFilters["transport_order_template.tonnage"];
    //    var transport_order_template_cubic       = this.serverParams.columnFilters["transport_order_template.cubic"];

    //   return this.$http
    //     .get(
    //       baseURI +
    //         `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&company_name=${company_name}&transport_order_template_tonnage=${transport_order_template_tonnage}&transport_order_template_cubic=${transport_order_template_cubic}&transport_order_template_description=${transport_order_template_description}&order_status=${this.serverParams.columnFilters.order_status}&postpone_time=${this.serverParams.columnFilters.postpone_time}&order_no=${this.serverParams.columnFilters.order_no}&dest_name=${this.serverParams.columnFilters.dest_name}&order_date=${date_order}&pick_plan=${pick_plan}&dest_plan=${dest_plan}&download=download`,

    //       {
    //         responseType: "blob",
    //         timeout: 20000
    //       }
    //     )
    //     .then((response) => {

    //       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //       var fileLink = document.createElement("a");

    //       fileLink.href = fileURL;
    //       fileLink.setAttribute("download", file_name);
    //       document.body.appendChild(fileLink);
    //       fileLink.click();
    //       this.isLoading = false;
    //     }).catch(error => {
    //       this.isLoading = false;
    //       if(error.code == "ECONNABORTED"){
    //         this.error(this.$t("check_internet_connection_txt"));
    //       }else{

    //         if (error.response) {
    //           if(error.response.status === 422) {
    //                   this.errors = error.response.data.errors;
    //                   this.resultError(error.response.data.errors);
    //           }else if (error.response.status === 500) {
    //               this.$router.push('/server-error');
    //           }else if (error.response.status === 401) {
    //               this.$router.push("/authorized-error");
    //           }else{
    //               this.$router.push('/page-not-found');
    //           }
    //         }

    //       }

    //     });
    // },



    // editData(index, row) {
    //   var params = this.$onRandom(row.transport_order_header_id);
    //   window.location.href = "/ct-orders/edit/" + params;
    // },

    // detailData(index, row) {
    //   var params = this.$onRandom(row.transport_order_header_id);
    //   window.location.href = "/ct-orders/detail/" + params;
    // },


    // // load items is what brings back the rows from server
    loadItemsMaps(order) {
      this.showMaps = false;
      this.rows = [];
      this.locations = [];
      this.zoom = 9;
      this.center = {
        lat: order.pick_latitude,
        lng: order.pick_longitude,
      },
        this.locations.push({
          id: order.transport_order_header_id,
          lat: order.pick_latitude,
          lng: order.pick_longitude,
          label: order.vehicle_no,
          icon: {
            path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
            fillColor: '#d04437',
            fillOpacity: 1,
            strokeColor: '#000',
            strokeWeight: 1,
            scale: 1
          },
          dataAll: "",

        });
      const baseURI = this.$settings.endPointCt + "truck-priority/select/" + order.transport_order_header_id;
      return this.$http
        .get(
          baseURI,
          { timeout: 10000 }
        )
        .then((response) => {

          this.rows = response.data.datas;
          this.rows.forEach((res) => {
            var marker = "";
            if (res.priority == 1) {
              marker = require("../../../assets/1.png");
            }

            if (res.priority == 2) {
              marker = require("../../../assets/2.png");
            }

            if (res.priority == 3) {
              marker = require("../../../assets/3.png");
            }

            if (res.priority == 4) {
              marker = require("../../../assets/4.png");
            }

            if (res.priority == 5) {
              marker = require("../../../assets/5.png");
            }

            if (res.priority == 6) {
              marker = require("../../../assets/6.png");
            }

            if (res.priority == 7) {
              marker = require("../../../assets/7.png");
            }

            if (res.priority == 8) {
              marker = require("../../../assets/8.png");
            }


            if (res.priority == 9) {
              marker = require("../../../assets/9.png");
            }

            if (res.priority == 10) {
              marker = require("../../../assets/10.png");
            }
            this.locations.push({
              id: res.transport_order_header_id,
              lat: res.latitude,
              lng: res.longitude,
              label: res.vehicle_no,
              // icon: {
              //   path: 'M 0 0 C 6.625 -7.69 5.825 -2.318 10.132 0.035 L 4.627 -15.371',
              //   fillColor: '#67001a',
              //   fillOpacity: 1,
              //   strokeWeight: 1,
              //   strokeColor: '#67001a',
              //   scale: 1.5,
              // },
              dataAll: res,
              icon: {
                url: marker,
                size: { width: 30, height: 30, f: "20px", b: "20px" },
                scaledSize: { width: 30, height: 30, f: "20px", b: "20px" },
                rotation: 45,
                color: 'blue'
              },
            });
          });

          // this.totalRecords = response.data.datas.total;
          // this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    // updateParams(newProps) {
    //   this.serverParams = Object.assign({}, this.serverParams, newProps);
    //   this.loadItems();
    // },

    // onColumnFilter(params) {
    //   this.updateParams(params);
    // },

    // onPageChange(params) {
    //   this.updateParams({ page: params.currentPage });
    // },

    // onSortChange(params) {
    //   this.updateParams({
    //     sort: {
    //       type: params[0].type,
    //       field: params[0].field,
    //     },
    //   });
    // },

    // onPerPageChange(params) {
    //   this.updateParams({ per_page: params.currentPerPage });
    // },

    // resultError(data) {
    //   var count = Object.keys(data).length;
    //   for (var x = 0; x < count; x++) {
    //     var nameOb = Object.keys(data)[x];
    //     var objectData = data[nameOb];
    //     for (var y = 0; y < objectData.length; y++) {
    //       this.error(objectData[y]);
    //     }
    //   }
    // },

    // success(kata) {
    //   const Toast = this.$swal.mixin({
    //     toast: true,
    //     position: "top-end",
    //     showConfirmButton: false,
    //     timer: 3000,
    //     timerProgressBar: true,
    //     didOpen: (toast) => {
    //       toast.addEventListener("mouseenter", this.$swal.stopTimer);
    //       toast.addEventListener("mouseleave", this.$swal.resumeTimer);
    //     },
    //   });
    //   Toast.fire({
    //     icon: "success",
    //     title: kata,
    //   });
    // },

    // success2(msg){
    //   let pesan="";
    //   msg.forEach((item) => {
    //       pesan=pesan+"<p>* "+item+"</p>";
    //   });

    //   this.$swal({
    //     icon: 'info',
    //     backdrop:true,
    //     title: "Information",
    //     html:pesan,
    //     showConfirmButton: true,
    //     allowOutsideClick: false,
    //     allowEscapeKey: false
    //   })


    // },


    // loadingSubmiting(){


    //   this.$swal({
    //     icon: 'warning',
    //     backdrop:true,
    //     title: "Please wait",
    //     html:'<h2>SUBMITTING ORDER...</h2>',
    //     showConfirmButton: false,
    //     allowOutsideClick: false,
    //     allowEscapeKey: false
    //   })

    // },


    // error(kata) {
    //   const Toast = this.$swal.mixin({
    //     toast: true,
    //     position: "top-end",
    //     showConfirmButton: false,
    //     timer: 3000,
    //     timerProgressBar: true,
    //     didOpen: (toast) => {
    //       toast.addEventListener("mouseenter", this.$swal.stopTimer);
    //       toast.addEventListener("mouseleave", this.$swal.resumeTimer);
    //     },
    //   });
    //   Toast.fire({
    //     icon: "error",
    //     title: kata,
    //   });
    // },

    // fade(sType) {
    //   this.isLoading = sType;
    // },

    // loading() {
    //   this.fade(true);
    //   setTimeout(() => {
    //     this.isLoading = false;
    //   }, 1000); // hide the message after 3 seconds
    // },


    // logout() {
    //   this.$logoutUser();
    //   this.$router.push("/");
    // },

    // getSystemCode() {
    //   const baseURI = this.$settings.endPoint + "system-code/select?system_code=ORDER_RESPONSE_TIME";

    //   return this.$http
    //     .get(
    //        baseURI,
    //       { timeout: 10000 }
    //     )
    //     .then((response) => {
    //       var rows = response.data.datas.data;
    //       if(rows.length > 0){
    //         this.ORDER_RESPONSE_TIME = rows[0].value;
    //       }

    //     }).catch(error => {

    //       if(error.code == "ECONNABORTED"){
    //         this.error(this.$t("check_internet_connection_txt"));
    //       }else{

    //         if (error.response) {
    //           if(error.response.status === 422) {
    //                   this.errors = error.response.data.errors;
    //                   this.resultError(error.response.data.errors);
    //           }else if (error.response.status === 500) {
    //               this.$router.push('/server-error');
    //           }else if (error.response.status === 401) {
    //               this.$router.push("/authorized-error");
    //           }else{
    //               this.$router.push('/page-not-found');
    //           }
    //         }

    //       }

    //     });
    // },

  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    // this.loading();
    //this.getSystemCode();


  },
};
</script>
<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.card-body {
  padding: 5px 1.5rem 1rem;
}

.vgt-table th {
  text-align: left !important;
}
</style>