<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading">
      {{ $t("loadingTxt") }}...
    </div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="BranchDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Rute Detail {{ $route.query.params }}
                  </h6>

                  <button
                    class="btn btn-light float-end"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div
                    class="row mb-3 collapse"
                    id="collapseExample"
                    style="padding-left: 20px"
                  >
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created By:</label>
                      <p class="text-muted">{{ this.forms.create_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{ this.forms.created_at }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{ this.forms.update_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{ this.forms.updated_at }}</p>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="d-flex justify-content-between align-items-baseline mb-2"
                    ></div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("route_master_code") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="route_master_code"
                          :placeholder="$t('route_master_code')"
                          v-model="forms.route_master_code"
                          disabled
                        />
                        <div v-if="errors.route_master_code">
                          <div
                            v-for="error in errors.route_master_code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("route_name") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="route_name"
                          :placeholder="$t('route_name')"
                          v-model="forms.route_name"
                          readonly
                        />

                        <div v-if="errors.route_name">
                          <div
                            v-for="error in errors.route_name"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >User Request</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="route_name"
                          placeholder=""
                          v-model="forms.user_request"
                          readonly
                        />

                        <div v-if="errors.user_request">
                          <div
                            v-for="error in errors.user_request"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Haul</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="route_name"
                          placeholder=""
                          v-model="forms.haul"
                          readonly
                        />

                        <div v-if="errors.haul">
                          <div
                            v-for="error in errors.haul"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("pool_start") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <!-- <v-select
                          :options="fetchPool"
                          v-model="poolStart"
                          return-object
                          label="pool_name"
                          @search="asyncPool"
                          @input="setOriginCordinate"
                        ></v-select> -->
                        <input
                          type="text"
                          class="form-control"
                          v-model="forms.pool_start"
                          readonly
                        />
                        <div v-if="errors.route_origin">
                          <div
                            v-for="error in errors.route_origin"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("pool_end") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          readonly
                          v-model="forms.pool_end"
                        />
                        <div v-if="errors.route_origin">
                          <div
                            v-for="error in errors.route_origin"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("route_start") }}</label
                        >
                      </div>
                      <div class="col-lg-10">
                      
                        <div class="row">
                          <div class="col-sm-3">
                            <!-- <v-select
                              :options="fetchLocation"
                              id="location"
                              v-model="route_origin"
                              @search="asyncSearchLocation"
                              label="location_code"
                              placeholder="Stop Location"
                              @input="routeOriginSelected($event)"
                            >
                            </v-select> -->
                            <input type="text" :value="forms.route_origin" readonly class="form-control">
                          </div>
                          <div class="col-sm-3">
                            <!-- <v-select
                              :options="fetchProvince"
                              id="origin_province"
                              v-model="forms.province_origin"
                              @search="asyncProvince"
                              label="province"
                              :reduce="province=> province.province"
                              placeholder="Select Province"
                              @input="provinceSelected('origin',$event)"
                            >
                            </v-select> -->

                            <input type="text" :value="forms.province_origin" readonly class="form-control">

                          </div>
                          <div class="col-sm-3">
                            <input type="text" :value="forms.city_origin" readonly class="form-control">
                            <!-- <v-select
                            id="city-origin"
                              :options="fetchCityOri"
                              label="city"
                              placeholder="Select City"
                              v-model="forms.city_origin" 
                              :reduce="city=> city.city"
                              @input="citySelected('origin',$event)"
                            >

                            </v-select> -->
                            <!-- <v-select
                              
                             
                            >
                            </v-select> -->
                          </div>
                          <!-- <div class="col-sm-3">
                            <v-select
                              :options="fetchDistrictOri"
                              label="district"
                              placeholder="Select District"
                              v-model="forms.route_origin"
                              :reduce="district=> district.district"
                            >
                            </v-select>
                          </div> -->
                          <div class="col-sm-3">
                            <input type="text" :value="forms.pick_state" readonly class="form-control">
                          </div>
                        </div>
                        <div v-if="errors.route_origin">
                          <div
                            v-for="error in errors.route_origin"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("route_end") }}</label
                        >
                      </div>
                      <div class="col-lg-10">
                      
                        <div class="row">
                          <div class="col-sm-3">
                            
                            <input type="text" :value="forms.route_destination" readonly class="form-control">
                          </div>
                          <div class="col-sm-3">
                            <!-- <v-select
                              :options="fetchProvince"
                              id="origin_province"
                              v-model="forms.province_origin"
                              @search="asyncProvince"
                              label="province"
                              :reduce="province=> province.province"
                              placeholder="Select Province"
                              @input="provinceSelected('origin',$event)"
                            >
                            </v-select> -->

                            <input type="text" :value="forms.province_destination" readonly class="form-control">

                          </div>
                          <div class="col-sm-3">
                            <input type="text" :value="forms.city_destination" readonly class="form-control">
                            <!-- <v-select
                            id="city-origin"
                              :options="fetchCityOri"
                              label="city"
                              placeholder="Select City"
                              v-model="forms.city_origin" 
                              :reduce="city=> city.city"
                              @input="citySelected('origin',$event)"
                            >

                            </v-select> -->
                            <!-- <v-select
                              
                             
                            >
                            </v-select> -->
                          </div>
                          <!-- <div class="col-sm-3">
                            <v-select
                              :options="fetchDistrictOri"
                              label="district"
                              placeholder="Select District"
                              v-model="forms.route_origin"
                              :reduce="district=> district.district"
                            >
                            </v-select>
                          </div> -->
                          <div class="col-sm-3">
                            <input type="text" :value="forms.drop_state" readonly class="form-control">
                          </div>
                        </div>
                        <div v-if="errors.route_origin">
                          <div
                            v-for="error in errors.route_origin"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <!-- <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("coordinate_end") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="destination_coordinate"
                          :placeholder="$t('coordinate_end')"
                          v-model="forms.destination_coordinate"
                          disabled
                        />
                        <div v-if="errors.destination_coordinate">
                          <div
                            v-for="error in errors.destination_coordinate"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Lead Time <span class="wajib">*</span></label
                        >
                      </div>
                      <div class="col-lg-2">
                        <input
                          type="text"
                          class="form-control"
                          id="lead time"
                          placeholder="Lead time"
                          v-model.number="forms.leadtime"
                          readonly
                          min="1"
                        />
                        <div v-if="errors.leadtime">
                          <div
                            v-for="error in errors.leadtime"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <b style="margin-top: 20px">Hari</b>
                      </div>
                    </div>
                    <!-- <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Lead Time POD <span class="wajib">*</span></label
                        >
                      </div>
                      <div class="col-lg-2">
                        <input
                          type="number"
                          class="form-control"
                          id="lead time"
                          placeholder="Lead time POD"
                          v-model.number="forms.leadtime_pod"
                          readonly
                          min="1"
                        />
                        <div v-if="errors.leadtime_pod">
                          <div
                            v-for="error in errors.leadtime_pod"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <b style="margin-top: 20px">Hari</b>
                      </div>
                    </div> -->

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("pooltoloading") }} <span class="wajib">*</span></label
                        >
                      </div>
                      <div class="col-lg-2">
                        <input
                         
                          type="number"
                          class="form-control"
                          id="pooltoloading"
                          :placeholder="$t('pooltoloading')"
                          v-model.number="forms.pooltoloading"
                          step="any"
                         
                          readonly
                          min="0"
                        />
                        <div v-if="errors.pooltoloading">
                          <div
                            v-for="error in errors.pooltoloading"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <b style="margin-top: 20px">Km</b>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("loadtounload") }} <span class="wajib">*</span></label
                        >
                      </div>
                      <div class="col-lg-2">
                        <input
                        
                          type="number"
                          class="form-control"
                          id="loadtounload"
                          :placeholder="$t('loadtounload')"
                          v-model.number="forms.loadtounload"
                          step="any"
                          readonly
                          min="1"
                         
                        />
                        <div v-if="errors.loadtounload">
                          <div
                            v-for="error in errors.loadtounload"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <b style="margin-top: 20px">Km</b>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("unloadtopool") }} <span class="wajib">*</span></label
                        >
                      </div>
                      <div class="col-lg-2">
                        <input
                        
                          type="number"
                          class="form-control"
                          id="unloadtopool"
                          :placeholder="$t('unloadtopool')"
                          v-model.number="forms.unloadtopool"
                          step="any"
                          readonly
                          min="0"
                         
                        />
                        <div v-if="errors.unloadtopool">
                          <div
                            v-for="error in errors.unloadtopool"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <b style="margin-top: 20px">Km</b>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("distance") }} Total</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="distance"
                          :placeholder="$t('distance')"
                          v-model.number="forms.distance"
                          readonly
                        />
                        <div v-if="errors.distance">
                          <div
                            v-for="error in errors.distance"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Margin</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="margin"
                          :placeholder="'Margin'"
                          v-model.number="forms.margin"
                          readonly
                        />
                        <div v-if="errors.distance">
                          <div
                            v-for="error in errors.distance"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("distance") }} + Margin</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="distance"
                          :placeholder="$t('distance') + ' Margin'"
                          v-model.number="forms.distance_margin"
                          readonly
                        />
                        <div v-if="errors.distance">
                          <div
                            v-for="error in errors.distance"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Pool to Load Link</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="distance"
                          v-model="forms.pooltoload_link"
                          readonly
                        />
                        <div v-if="errors.pooltoload_link">
                          <div
                            v-for="error in errors.pooltoload_link"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Load To Unload Link</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="distance"
                          readonly
                          v-model="forms.loadtounload_link"
                        
                        />
                        <div v-if="errors.loadtounload_link">
                          <div
                            v-for="error in errors.loadtounload_link"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Unload To Pool Link</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="distance"
                          readonly
                          v-model="forms.unloadtopool_link"
                         
                        />
                        <div v-if="errors.unloadtopool_link">
                          <div
                            v-for="error in errors.unloadtopool_link"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Pool to Load Link Default</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" readonly class="form-control" id="distance" v-model="forms.pooltoload_link_default" />
                        <div v-if="errors.pooltoload_link_default">
                          <div v-for="error in errors.pooltoload_link_default" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Load to Unload Link Default</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" readonly class="form-control" id="distance" v-model="forms.loadtounload_link_default" />
                        <div v-if="errors.loadtounload_link_default">
                          <div v-for="error in errors.loadtounload_link_default" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Unload to Pool Link Default</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" readonly class="form-control" id="distance" v-model="forms.unloadtopool_link_default" />
                        <div v-if="errors.unloadtopool_link_default">
                          <div v-for="error in errors.unloadtopool_link_default" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Upload File</label
                        >
                      </div>
                      <div class="col-lg-8">
                                  <div
                                    v-if="photo_temporary"
                                    class="dropify-wrapper has-preview"
                                  >
                                    <!-- <div class="dropify-message">
                                      <span
                                        class="file-icon"
                                        v-if="!photo_temporary"
                                      >
                                        <p>
                                          Drag and drop a file here or click
                                        </p>
                                      </span>
                                      <p class="dropify-error">
                                        Ooops, something wrong appended.
                                      </p>
                                    </div> -->
                                    <div
                                      class="dropify-loader"
                                      style="display: none"
                                    ></div>
                                    <div class="dropify-errors-container">
                                      <ul></ul>
                                    </div>
                                    <!-- <input
                                      type="file"
                                      ref="images"
                                      accept="image/*"
                                      class="form-control"
                                      
                                    /> -->
                                    <!-- <button
                                      type="button"
                                      class="dropify-clear"
                                      @click="removeImages"
                                    >
                                      Remove
                                    </button> -->
                                    <div
                                      class="dropify-preview"
                                      style="display: block"
                                    >
                                      <img
                                        :src="photo_temporary"
                                        width="90%"
                                        height="80%"
                                      />
                                      <span class="dropify-render"></span>
                                      <!-- <div class="dropify-infos">
                                        <div class="dropify-infos-inner">
                                          <p class="dropify-filename"></p>
                                          <p class="dropify-infos-message">
                                            Drag and drop or click to replace
                                          </p>
                                        </div>
                                      </div> -->
                                    </div>
                                  </div>
                                  <div v-else class="dropify-wrapper">
                                    <!-- <div class="dropify-message">
                                      <span class="file-icon">
                                        <p>
                                          Drag and drop a file here or click
                                        </p>
                                      </span>
                                    </div> -->
                                    <div
                                      class="dropify-loader"
                                      style="display: none"
                                    ></div>
                                    <div class="dropify-errors-container">
                                      <ul></ul>
                                    </div>
                                    <!-- <input
                                      type="file"
                                      ref="images"
                                      accept="image/*"
                                      class="form-control"
                                      @change="handleFileUpload($event)"
                                    /> -->
                                    <!-- <button type="button" class="dropify-clear">
                                      Remove
                                    </button> -->
                                    <div
                                      class="dropify-preview"
                                      style="display: none"
                                    >
                                      <img
                                        :src="photo_temporary"
                                        width="90%"
                                        height="80%"
                                      />
                                      <span class="dropify-render"></span>
                                      <!-- <div class="dropify-infos">
                                        <div class="dropify-infos-inner">
                                          <p class="dropify-filename"></p>
                                          <p class="dropify-infos-message">
                                            Drag and drop or click to replace
                                          </p>
                                        </div>
                                      </div> -->
                                    </div>
                                  </div>

                                  <div v-if="errors.picture">
                                    <div
                                      v-for="error in errors.picture"
                                      :key="error"
                                      class="alert alert-primary"
                                      role="alert"
                                    >
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                    </div>

                    <!-- <div class="row">
                      <div class="col-sm-12">
                        <div class="card">
                          <div class="card-header">
                            <h5>Maps</h5>
                          </div>

                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-12">
                                <div class="mb-3">
                                  <gmap-map
                                    :zoom="8"
                                    :center="center"
                                    style="width: 100%; height: 400px"
                                    map-type-id="terrain"
                                  >
                                    <gmap-marker
                                      :key="index"
                                      v-for="(gmp, index) in markers"
                                      :position="gmp.position"
                                      @click="center = gmp.position"
                                    >
                                      <gmap-info-window :opened="true">
                                        <span
                                          class="badge"
                                          :style="{
                                            'background-color': 'white',
                                            color: 'black',
                                          }"
                                          :opened="false"
                                        >
                                          {{ gmp.position.label }}
                                        </span>
                                      </gmap-info-window>
                                    </gmap-marker>

                                    <DirectionsRenderer
                                      travelMode="DRIVING"
                                      :origin="startLocation"
                                      :destination="endLocation"
                                      :waypoints="waypnt"
                                    />
                                  </gmap-map>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <div class="card-footer">
                      <button
                        class="btn btn-warning text-black fw-bolder btn-icon-text"
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      <a
                        v-if="permision_role.method_delete"
                        class="btn btn-danger text-white fw-bolder btn-icon-text float-end"
                        @click="deleteData()"
                      >
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>

                      <a
                        v-if="permision_role.method_update"
                        class="btn btn-primary me-2 text-white fw-bolder btn-icon-text float-end"
                        @click="editData()"
                      >
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
// import DirectionsRenderer from "@/middleware/DirectionsRenderedDynamic";

export default {
  name: "BranchDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    // DirectionsRenderer,
  },
  data() {
    const mapMarkerIcon = require("../../../assets/icontruck.png");

    return {
      poolAll: [],
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      forms: [],

      markerOption: {
        url: mapMarkerIcon,
        size: { width: 30, height: 30, f: "20px", b: "20px" },
        scaledSize: { width: 30, height: 30, f: "20px", b: "20px" },
        rotation: 45,
      },
      markers: [],
      center: {
        lat: -2.548926,
        lng: 118.0148634,
      },
      startLocation: null,
      endLocation: null,
      waypnt: [],
      photo_temporary: null,
      photo: "",
    };
  },
  watch: {},
  methods: {
    isNumberLatLong(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 45
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    resetForm() {},

    cek_message(status, msg) {
      if (status == "200") {
        this.success(msg);
      }
    },

    editData() {
      var params = this.$route.params.id;
      this.params=this.$route.query.params;
      var url="";
      if(this.params=='request'){
        url= "?params=request";
      }else if(this.params=='approval'){
        url = "?params=approval";
      }

      window.location.href = "/rute/edit/" + params+url;
    },

    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "rm-route-master/delete/" + id;
         
            this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/rute";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    backForm() {
      this.params=this.$route.query.params;
      var url="";
      if(this.params=='request'){
        url= "/rute?params=request";
      }else if(this.params=='approval'){
        url = "/rute?params=approval";
      }else{
        url="/rute"
      }
    
      window.location.href =url;
    },

    loadData(id) {
      this.isLoading = true;
      const baseURI = this.$settings.endPoint + "rm-route-master/detail/" + id;
      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.isLoading = false;

          this.forms = response.data.datas;
          this.forms.pool_start = response.data.datas.pool_start_name;
          this.forms.pool_end = response.data.datas.pool_end_name;
          this.photo_temporary=response.data.datas.picture;

          var markerPickup = {
            lat: this.forms.start.pool_latitude,
            lng: this.forms.start.pool_longitude,
            label: this.$t("coordinate_start"),
          };
          var markerDestination = {
            lat: this.forms.end.pool_latitude,
            lng: this.forms.end.pool_longitude,
            label: this.$t("coordinate_end"),
          };
          this.endLocation = markerDestination;
          this.center = markerPickup;
          this.startLocation = markerPickup;
          var startRoute = this.forms.origin_coordinate.split(",");
          this.waypnt.push({
            location: {
              lat: parseFloat(startRoute[0].replace(/\s+/g, "")),
              lng: parseFloat(startRoute[1].replace(/\s+/g, "")),
            },
            stopover: true,
          });
          var endRoute = this.forms.destination_coordinate.split(",");
          this.waypnt.push({
            location: {
              lat: parseFloat(endRoute[0].replace(/\s+/g, "")),
              lng: parseFloat(endRoute[1].replace(/\s+/g, "")),
            },
            stopover: true,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },


    

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmRouteMasterController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status, this.$route.query.msg);
    this.loadData(params[0]);
    this.fetchIt();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.dropify-wrapper{
  height: 500px;
}
</style>
